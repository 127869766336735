import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Dialog, DialogContent, DialogTitle, DialogActions,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useHistory, useLocation } from 'react-router-dom';
import useSwitchCompany from '../../api/useSwitchCompany';
import { useUser } from '../../contexts/UserContext';
import CustomButton from '../MaterialUI/CustomButton';

const ConfirmSwitchCompanyModal = ({
  open, setModal, company, customReload, reloadRoute,
}) => {
  const { user } = useUser();
  const history = useHistory();
  const { pathname } = useLocation();

  const {
    loading, loaded, error, getCompanyInfo,
  } = useSwitchCompany();

  const handleClose = () => {
    setModal(false);
  };

  const onConfirm = () => {
    getCompanyInfo(user.id, company.id, () => {
      window.sessionStorage.clear();
      handleClose();
      if (customReload) {
        history.push(reloadRoute);
        return window.location.reload();
      }
      const fullPathname = pathname.split('/');
      const mainPathname = fullPathname[1];
      const parentPathname = fullPathname[2];
      history.push(`/${mainPathname}/${parentPathname}`);
      return window.location.reload();
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="offered-dialog-title"
    >
      <DialogTitle id="offered-dialog-title">Switch company</DialogTitle>
      <DialogContent>
        Are you sure to switch to company
        {' '}
        {company?.name}
        ?
        { error && <Alert serverity="error">{error}</Alert> }
      </DialogContent>
      <DialogActions>
        <CustomButton
          disableElevation
          onClick={onConfirm}
          variant="contained"
          color="primary"
          loading={loading}
          loaded={loaded}
        >
          Confirm
        </CustomButton>
        <Button
          disableElevation
          onClick={handleClose}
          variant="contained"
          color="inherit"
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmSwitchCompanyModal.defaultProps = {
  customReload: false,
  reloadRoute: '',
};

ConfirmSwitchCompanyModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setModal: PropTypes.func.isRequired,
  company: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  customReload: PropTypes.bool,
  reloadRoute: PropTypes.string,
};

export default ConfirmSwitchCompanyModal;
