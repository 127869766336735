import { companyTypes } from '../constants';

export const loadSessionStorage = (key) => {
  const item = window.sessionStorage.getItem(key);
  return item != null ? JSON.parse(item) : null;
};

export const addRouteGroup = (path, options = {}, fragment = {}) => {
  const {
    layout = null,
    restrictCompanyType = undefined,
    trackAnalytics = true,
  } = options;
  const { props } = fragment;

  if (props && props.children) {
    return props.children.map(
      (route) => ({
        ...route,
        props: {
          ...route.props,
          defaultPath: path,
          path: `${path}${route.props.path}`,
          layout,
          restrictCompanyType,
          trackAnalytics,
        },
      }),
    );
  }

  return null;
};

export const currency = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const offerCurrency = (value) => currency.format(value);

export const addresses = {
  format: ({
    address1,
    address2,
    city,
    state,
    zip,
  }) => (address2
    ? `${address1}
${address2}
${city} ${state} ${zip}`
    : `${address1}
${city} ${state} ${zip}`),
};

export const getPropertiesByStringValue = (object, value) => value.split('.').reduce((acc, curr) => acc[curr], object);

export const displayPercent = (value) => `${(value * 100).toFixed(2)}%`;

export const isEmptyObject = (object) => Boolean(!Object.keys(object).length);

export const numberCleaned = (number) => `${number}`.replace(/\D/g, '');

export const phoneNumberFormat = (phoneNumber) => {
  const cleaned = numberCleaned(phoneNumber);
  if (cleaned.length === 10) {
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
  }
  return phoneNumber;
};

export const einNumberFormat = (ein) => {
  const cleaned = numberCleaned(ein);
  if (cleaned.length === 9) {
    const match = cleaned.match(/^(\d{2})(\d{7})$/);
    if (match) {
      return `${match[1]}-${match[2]}`;
    }
  }
  return ein;
};

export const downloadFile = (title, type, data) => {
  const blobObject = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = blobObject;
  link.setAttribute('download', `${title}.${type}`);
  document.body.appendChild(link);
  link.click();
  return true;
};

export const additionListOfNumbers = (list) => list.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

export const formatRolActionsList = (roles, userCompanyId) => {
  if (roles.length > 0) {
    const list = [];
    roles.filter((current) => current.companyId === userCompanyId).map((rol) => (
      rol.options.map((option) => list.push(option.path))
    ));
    // Remove duplicates
    return [...new Set(list)];
  } return [];
};

export const hasActionPermission = (action, actionsList, companyType) => (companyType === companyTypes.STRUXTION ? true : actionsList.includes(action));

export const orderRelatedCompanies = (data) => data.sort((a, b) => {
  if (a.sequenceNumber === null) {
    return 1;
  }

  if (b.sequenceNumber === null) {
    return -1;
  }

  if (a.sequenceNumber === b) {
    return 0;
  }

  return a.sequenceNumber < b.sequenceNumber ? -1 : 1;
}).map((related, key) => ({ ...related, sequenceNumber: related.sequenceNumber || key + 1 }));

// FOR PAYMENT INSTRUCION SCHEDULE DATE
const dateNow = new Date();
const ET = dateNow.toLocaleTimeString('en-US', {
  timeZone: 'America/New_York',
  hour12: false,
});

const timeValidation = (hour, minutes, userSetting) => {
  const { settingValue } = userSetting;
  const [settingHour, settingMinute] = settingValue.split(':').map(Number);
  return hour < settingHour || (hour === settingHour && minutes < settingMinute);
};

const arrayDate = ET.split(':', 3);
export const isScheduleProgrammable = (userSetting) => timeValidation(parseInt(arrayDate[0], 10), parseInt(arrayDate[1], 10), userSetting);

export const validateZipCodes = (zipValue) => {
  if (zipValue.length <= 5) {
    const regex = new RegExp(/\b\d{5}\b/g);
    return regex.test(zipValue);
  }
  if (zipValue.length > 5 || zipValue.length <= 10) {
    const regex = new RegExp(/^([0-9]{5})\)?[-]?([0-9]{4})?$/);
    return regex.test(zipValue);
  }
  return false;
};

export const findSameIdInArrays = (array1, array2, key = 'id') => {
  const arrayIds = new Set(array1.map((item) => item[key]));
  const sameIds = array2.filter((item) => arrayIds.has(item[key]))
    .map((item) => item[key]);
  return sameIds;
};

// For DJC
export const isDJCPayment = (status) => status.includes('djc');
export const hasChildPayments = (item) => Array.isArray(item?.djcPaymentsList) && item?.djcPaymentsList.length;
// For DJC ACTIONS
export const isEveryMembersHasStatus = (members, status) => Array.isArray(members) && members.length && members.every((member) => member.paymentInstructionStatusEnum.includes(status));
