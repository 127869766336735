import React from 'react';
import PropTypes from 'prop-types';
import {
  AppBar as MUIAppBar,
  Box,
  Button,
  Hidden,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  Link as MuiLink,
  makeStyles,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MenuIcon from '@material-ui/icons/Menu';
import { Link, useHistory } from 'react-router-dom';
import { BasicIcons } from '../icons';
import {
  drawerWidth, companyTypes, XPAY_COMPANY_KB_LINK, XPAY_GC_KB_LINK,
} from '../../constants';
import { useKeycloak } from '../../contexts/KeycloakContext';
import useScrollPosition from '../../hooks/useScrollPosition';
import logo from '../../logo-x.svg';
import ChangePhotoModal from '../CropPhoto/ChangePhotoModal';
import { eppGC, eppSC, scRoot } from '../../constants/navigation';
import { useUser } from '../../contexts/UserContext';
import PreferredCompaniesModal from '../Multicompany/PreferredCompaniesModal';
import MulticompanyMenuItem from './MulticompanyMenuItem';
import HiddenByPermission from '../HiddenByPermission';
import { ACTIONS_ROLES } from '../../constants/roles';
import useSwitchCompany from '../../api/useSwitchCompany';
import EmailNotifications from '../Multicompany/EmailNotifications';

const useStyles = makeStyles((theme) => {
  const altBackgroundStyle = {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[4],
  };

  const fontStyle = {
    color: theme.palette.text.primary,
    fontWeight: '500',
  };

  return ({
    appBar: {
      [theme.breakpoints.up('md')]: {
        width: ({ hasDrawer }) => (hasDrawer ? `calc(100% - ${drawerWidth}px)` : '100%'),
        marginLeft: drawerWidth,
        backgroundColor: 'transparent',
        boxShadow: theme.shadows[0],
      },
      [theme.breakpoints.down('sm')]: altBackgroundStyle,
      ...fontStyle,
    },
    appBarAlt: {
      ...altBackgroundStyle,
      ...fontStyle,
    },
    toolBar: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    accountMenuButton: {
      '&:hover': {
        backgroundColor: theme.palette.text.primaryFaded,
        color: 'white',
      },
      [theme.breakpoints.up('md')]: {
        backgroundColor: 'transparent',
      },
      [theme.breakpoints.down('sm')]: {
        backgroundColor: theme.palette.background.paper,
      },
      color: theme.palette.text.primary,
      boxShadow: theme.shadows[0],
    },
    profileIcon: {
      height: 50,
      width: 50,
      margin: '0 auto',
      padding: '0.2em',
      backgroundColor: theme.palette.text.secondary,
      border: 0,
      borderRadius: '50%',
    },
    profileIconImage: {
      width: 60,
      border: 0,
      height: 62,
      margin: '0 auto',
      padding: '0.2em',
      borderRadius: '50%',
      objectFit: 'contain',
      backgroundColor: ' #ffffff',
    },
    logo: {
      width: '40px',
      height: 'auto',
    },
    logoButton: {
      width: '90%',
    },
    link: {
      color: 'inherit',
      textDecoration: 'inherit',
    },
  });
});

export const AccountMenu = ({ companyId, companyName, username }) => {
  const classes = useStyles();
  const history = useHistory();
  const { user } = useUser();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);
  const [profilePhoto, setProfilePhoto] = React.useState('');
  const [preferredCompanies, setPreferredCompanies] = React.useState(false);
  const [emailNotifications, setEmailNotifications] = React.useState(false);

  const {
    getCompanyInfo,
  } = useSwitchCompany();

  const { keycloak } = useKeycloak();

  const eppCompany = user?.isEppCompany;
  const generalContractorCompany = user?.companyType === companyTypes.GENERAL_CONTRACTOR;
  const subContractorCompany = user?.companyType === companyTypes.SUB_CONTRACTOR;
  const showCompanyProfileButton = subContractorCompany || (eppCompany && generalContractorCompany);
  const isValidCompany = eppCompany && (generalContractorCompany || subContractorCompany);
  const KBUrl = generalContractorCompany ? XPAY_GC_KB_LINK : XPAY_COMPANY_KB_LINK;
  const showMultiCompanyOptions = (generalContractorCompany || subContractorCompany) && eppCompany;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleClose();
    if (user?.isEppCompany && user?.companyType === companyTypes.GENERAL_CONTRACTOR) {
      const defaultCompany = user?.relatedCompanies.find((company, key) => company.sequence === 1 || key === 0);
      getCompanyInfo(user?.id, defaultCompany.id, () => keycloak.logout());
    }
    window.sessionStorage.clear();
    return keycloak.logout();
  };

  const handleClickCompanyProfile = () => {
    if (eppCompany && generalContractorCompany) history.push(`${eppGC}/companyProfile`);
    if (eppCompany && subContractorCompany) history.push(`${eppSC}/companyProfile`);
    if (!eppCompany && subContractorCompany) history.push(`${scRoot}/companyProfile`);
    handleClose();
  };

  const openModalPhoto = () => {
    setOpenModal(true);
    handleClose();
  };

  const onClose = () => {
    setOpenModal(false);
  };

  return (
    <>
      <ChangePhotoModal logo={profilePhoto} setProfilePhoto={setProfilePhoto} open={openModal} onCancel={onClose} />
      <Hidden smDown implementation="css">
        <Button
          className={classes.accountMenuButton}
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
          variant="contained"
          disableElevation
          startIcon={!profilePhoto ? <BasicIcons.Person className={classes.profileIcon} white /> : <img className={classes.profileIconImage} src={profilePhoto} alt="Profile" />}
          endIcon={<ExpandMoreIcon />}
        >
          <Box display="flex" ml={1} flexDirection="column" textAlign="left">
            <Box>
              <Typography variant="subtitle2">{username}</Typography>
            </Box>
            <Box>
              <Typography style={{ fontSize: '0.86em' }}>{companyName}</Typography>
            </Box>
          </Box>
        </Button>
      </Hidden>
      <Hidden mdUp implementation="css">
        <IconButton
          aria-label="open account menu"
          edge="end"
          onClick={handleClick}
          id="menu-icon-button"
        >
          <BasicIcons.Person className={classes.profileIcon} white />
        </IconButton>
      </Hidden>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {
          keycloak.authenticated
            ? ([
              showMultiCompanyOptions ? <MulticompanyMenuItem key="multicompanyOptions" closeMenuBar={handleClose} /> : null,
              showMultiCompanyOptions ? <MenuItem key="preferredCompanies" onClick={() => { setPreferredCompanies(true); handleClose(); }} disabled={!companyId}>Preferred companies</MenuItem> : null,
              showMultiCompanyOptions ? <MenuItem key="emailNotifications" onClick={() => { setEmailNotifications(true); handleClose(); }} disabled={!companyId}>Email notification</MenuItem> : null,
              <HiddenByPermission key="changePhoto" permission={ACTIONS_ROLES.CHANGE_PHOTO}>
                <MenuItem onClick={openModalPhoto} disabled={!companyId}>Change profile photo</MenuItem>
              </HiddenByPermission>,
              showCompanyProfileButton ? <HiddenByPermission key="companyProfile" permission={ACTIONS_ROLES.VIEW_PROFILE}><MenuItem onClick={handleClickCompanyProfile} disabled={!companyId}>Company profile</MenuItem></HiddenByPermission> : null,
              isValidCompany ? <MenuItem key="kbUrl"><MuiLink href={KBUrl} target="_blank" underline="none" color="inherit">Support website</MuiLink></MenuItem> : null,
              <MenuItem key="logout" onClick={handleLogout}>Logout</MenuItem>,
            ])
            : <MenuItem><Link className={classes.link} to="/login">Login</Link></MenuItem>
        }
      </Menu>
      { emailNotifications ? <EmailNotifications open={emailNotifications} setModal={setEmailNotifications} /> : null }
      { preferredCompanies ? <PreferredCompaniesModal open={preferredCompanies} setModal={setPreferredCompanies} /> : null }
    </>
  );
};

const AppBar = ({
  toggleDrawer,
  hasDrawer,
  defaultPath,
  user: {
    companyId,
    companyName,
    username,
  },
  hideLogo,
}) => {
  const classes = useStyles({ hasDrawer });
  const scrollTrigger = useScrollPosition();

  const renderLogo = () => (
    <Link to={defaultPath}>
      <Button className={classes.logoButton}>
        <img className={classes.logo} src={logo} alt="Struxtion logo, navigate to dashboard" />
      </Button>
    </Link>
  );

  return (
    <MUIAppBar className={scrollTrigger ? classes.appBarAlt : classes.appBar}>
      <Toolbar className={classes.toolBar}>
        {hasDrawer && (
          <Hidden mdUp implementation="css">
            <IconButton
              aria-label="open drawer"
              edge="start"
              className={classes.menuButton}
              onClick={toggleDrawer}
              id="drawer-icon-button"
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
        )}
        {
          hideLogo ? (
            <>
              <Hidden smDown implementation="css" />
              <Hidden mdUp implementation="css">
                {renderLogo()}
              </Hidden>
            </>
          ) : renderLogo()
        }
        <AccountMenu username={username} companyName={companyName} companyId={companyId} />
      </Toolbar>
    </MUIAppBar>
  );
};

AccountMenu.defaultProps = {
  companyId: '',
  companyName: '',
  username: 'Account',
};

AccountMenu.propTypes = {
  companyId: PropTypes.string,
  companyName: PropTypes.string,
  username: PropTypes.string,
};

AppBar.defaultProps = {
  hideLogo: true,
};

AppBar.propTypes = {
  toggleDrawer: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]).isRequired,
  hasDrawer: PropTypes.bool.isRequired,
  defaultPath: PropTypes.string.isRequired,
  user: PropTypes.shape({
    companyId: PropTypes.string,
    username: PropTypes.string,
    companyName: PropTypes.string,
  }).isRequired,
  hideLogo: PropTypes.bool,
};

export default AppBar;
