import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { companyTypes, CURRENT_COMPANY_TYPE } from '../../constants';
import { loadSessionStorage } from '../../utils';

const useStyles = makeStyles(() => ({
  centerTheme: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '15px',
  },
}));

export default function PayorPayeeToggle({ isHidden }) {
  const location = useLocation();
  const urlParams = useMemo(() => new URLSearchParams(location.search), [location]);
  const classes = useStyles();
  const [selectedCompanyType, setSelectedCompanyType] = useState(() => loadSessionStorage(CURRENT_COMPANY_TYPE));
  const [isPayorSelected, setIsPayorSelected] = useState(selectedCompanyType && selectedCompanyType === companyTypes.GENERAL_CONTRACTOR);

  const changeCompanyType = (isPayorChanged) => {
    setIsPayorSelected(isPayorChanged);
    setSelectedCompanyType(isPayorChanged ? companyTypes.GENERAL_CONTRACTOR : companyTypes.SUB_CONTRACTOR);
    window.location.reload();
  };

  useEffect(() => {
    if (selectedCompanyType) {
      window.sessionStorage.setItem(CURRENT_COMPANY_TYPE, JSON.stringify(selectedCompanyType));
    }
  }, [selectedCompanyType]);

  useEffect(() => {
    if (urlParams && urlParams.size > 0) {
      const switchCompanyType = urlParams.get('switchCompanyType');
      if (switchCompanyType) {
        const companyType = switchCompanyType === '1' ? companyTypes.GENERAL_CONTRACTOR : companyTypes.SUB_CONTRACTOR;
        window.sessionStorage.setItem(CURRENT_COMPANY_TYPE, JSON.stringify(companyType));
        setIsPayorSelected(companyType === companyTypes.GENERAL_CONTRACTOR);
        setSelectedCompanyType(companyType);
        urlParams.delete('switchCompanyType');
        window.location.href = `${window.location.origin}${location.pathname}${urlParams.toString() ? `?${urlParams.toString()}` : ''}`;
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlParams]);

  return (
    <Box
      className={classes.centerTheme}
      aria-labelledby="payor-payee-toogle"
      style={{ display: isHidden ? 'none' : 'flex' }}
    >
      <Box
        display="flex"
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ mt: 1, mb: 1, gap: 0 }}
      >
        <Button size="small" variant="contained" color={isPayorSelected ? 'primary' : 'inherit'} style={{ maxHeight: '50px', borderRadius: '8px 0 0 8px' }} onClick={() => changeCompanyType(true)}>Make Payments</Button>
        <Button size="small" variant="contained" color={isPayorSelected ? 'inherit' : 'primary'} style={{ maxHeight: '50px', borderRadius: '0 8px 8px 0' }} onClick={() => changeCompanyType(false)}>Receive Payments</Button>
      </Box>
    </Box>
  );
}

PayorPayeeToggle.defaultProps = {
  isHidden: true,
};

PayorPayeeToggle.propTypes = {
  isHidden: PropTypes.bool,
};
