export const ROLES = {
  SUPER_ADMIN: 'super-admin',
  ADMIN: 'admin',
  PAYMENT_PROCESSOR: 'payment-processor',
  APPROVER: 'approver',
  FINAL_APPROVER: 'final-approver',
  EXTERNAL_APPROVER: 'external-approver',
  PROJECT_ACCOUNTANT: 'project-accountant',
  OBSERVER: 'observer',
  ADMINISTRATOR: 'administrator',
  GENERAL_CONTRACTOR: 'general_contractor',
  SUB_CONTRACTOR: 'sub_contractor',
  UNNUSED: 'unnused',
};

export const ROLES_TO_CONTACT_ID = {
  superAdmin: 5,
  admin: 6,
  paymentProcessor: 7,
  approver: 8,
  finalApprover: 9,
  externalApprover: 10,
  observer: 11,
  projectAccountant: 12,
  sub_contractor: 2,
  lien_waiver_signer: 13,
};

export const NAV_ROLES = {
  XPAY_GC:
    {
      DASHBOARD: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.PAYMENT_PROCESSOR, ROLES.APPROVER, ROLES.FINAL_APPROVER, ROLES.EXTERNAL_APPROVER, ROLES.PROJECT_ACCOUNTANT],
      COMPANIES: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.PAYMENT_PROCESSOR, ROLES.APPROVER, ROLES.FINAL_APPROVER, ROLES.PROJECT_ACCOUNTANT],
      PROJECTS: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.PAYMENT_PROCESSOR, ROLES.APPROVER, ROLES.FINAL_APPROVER, ROLES.EXTERNAL_APPROVER, ROLES.PROJECT_ACCOUNTANT],
      PAYMENTS: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.PAYMENT_PROCESSOR, ROLES.APPROVER, ROLES.FINAL_APPROVER, ROLES.EXTERNAL_APPROVER, ROLES.PROJECT_ACCOUNTANT],
      OP_ACCOUNT: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.FINAL_APPROVER, ROLES.PROJECT_ACCOUNTANT],
      STRX_ACCOUNT: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.PAYMENT_PROCESSOR, ROLES.APPROVER, ROLES.FINAL_APPROVER, ROLES.PROJECT_ACCOUNTANT],
      DOCUMENTS: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.PAYMENT_PROCESSOR, ROLES.APPROVER, ROLES.FINAL_APPROVER, ROLES.EXTERNAL_APPROVER, ROLES.PROJECT_ACCOUNTANT],
    },
};

export const ACTIONS_ROLES = {
  COMPANIES: 'companies',
  CHANGE_PHOTO: 'change-photo',
  VIEW_PROFILE: 'view-profile',
  EDIT_COMPANY: 'edit-company',
  EMAIL_DOMAINS: 'email-domains',
  ADD_CONTACT: 'add-contact',
  EDIT_CONTACT: 'edit-contact',
  REINVITE_CONTACT: 're-invite-contact',
  DELETE_CONTACT: 'delete-contact',
  LOGOUT: 'logout',
  GLOBAL_NOTIFICATIONS: 'global-notifications',
  GLOBAL_ACTIONS: 'global-actions',
  GLOBAL_COMPANY_PAYMENTS: 'global-company-payments',
  GC_COMPANY_SELECTOR: 'gc-company-selector',
  QTY_PROJECTS: 'qty-projects',
  QTY_COMPANIES: 'qty-companies',
  QTY_XPAY: 'qty-xpay',
  VIEW_PROJECT_DETAILS: 'view-project-details',
  VIEW_COMPANY_DETAILS: 'view-company-details',
  QTY_XPAY_PLUS: 'qty-xpay-plus',
  WATERFALL_GBA: 'waterfall-gba',
  RECENT_PAYMENTS: 'recent-payments',
  READ_SEARCH_FILTER: 'read-search-filters',
  ONBOARDING_REPORT: 'onboarding-report',
  ADD_COMPANY: 'add-company',
  REINVITE: 're-invite',
  XPAY_PLUS_READ: 'xpay-plus-read',
  XPAY_PLUS_VIEW_PROJECT_DETAILS: 'xpay-plus-view-project-details',
  XPAY_PLUS_VIEW_COMPANY_DETAILS: 'xpay-plus-view-company-details',
  EDIT_PROJECT: 'edit-project',
  OVERHEAD_PROJECT: 'overhead-project',
  DOWNLOAD_TEMPLATE: 'download-template',
  DOWNLOAD_TEMPLATE_PAYMENTS: 'download-template-payments',
  DOWNLOAD_TEMPLATE_PROJECTS: 'download-template-projects',
  DOWNLOAD_TEMPLATE_COMPANIES: 'download-template-companies',
  BULK_UPLOAD_PAYMENT: 'bulk-upload-payment',
  BULK_UPLOAD_PAYMENT_IN_A_PROJECT: 'bulk-upload-payment-in-a-project',
  BULK_UPLOAD_PROJECT: 'bulk-upload-projects',
  BULK_UPLOAD_COMPANIES: 'bulk-upload-companies',
  ADD_PROJECT: 'add-project',
  ASSING_APPROVER: 'assign-approver',
  ASSIGN_COMPANY: 'assign-company',
  XPAY_PLUS_ACTIONS: 'xpay-plus-actions',
  REINVITE_OFF_PLATFORM: 're-invite-offplatform',
  REINVITE_ON_PLATFORM: 're-invite-onplatform',
  DOWNLOAD_PAYMENT_TEMPLATE: 'download-payment-template',
  VIEW_BALANCE_GBA: 'view-balance-gba',
  VIEW_BALANCE_GOA: 'view-balance-goa',
  VIEW_PAYMENTS_TO_APPROVE: 'view-payments-to-approve',
  VIEW_PAYMENTS_TO_FINAL_APPROVE: 'view-payments-to-final-approve',
  BATCH_PAYMENT: 'batch-payment',
  ADD_SINGLE_PAYMENT: 'add-single-payment',
  DOWNLOAD_PAYMENTS_REPORT: 'download-payments-report',
  PAPER_CHECK_LINK: 'paper-check-link',
  REAUTHENTICATE: 'reauthenticate',
  CHANGE_ACCOUNT: 'change-account',
  COPY_DETAILS: 'copy-details',
  VIEW_BANK_ACCOUNT: 'view-bank-account',
  VIEW_AVAILABLE_BALANCE: 'view-available-balance',
  VIEW_ACCOUNT_BALANCE: 'view-accounts-balances',
  KYB_BUSINESS: 'kyb-business',
  KYC_OWNERS: 'kyc-owners',
  PULL_FROM_GOA: 'pull-from-goa',
  DEPOSIT_CHECK: 'deposit-check',
  BANK_STATEMENT: 'bank-statement',
  IN_PROCESS_TRANSACTIONS: 'in-process-transactions',
  ACCOUNT_ACTIVITY_LEDGER: 'account-activity-ledger',
  ADD_NEW: 'add-new',
  REVIEW: 'review',
  DOWNLOAD: 'download',
  ACTIONS_BUTTON_MENU: 'actions-button-menu',
  MORE_ACTIONS_BUTTON_MENU: 'more-actions-button-menu',
  DOWNLOAD_PAPER_CHECK_REPORT: 'download-paper-check-report',
  TRANSFER_GBA_TO_SRA: 'transfer-gba-to-sra',
  EDIT_PAYMENT_INSTRUCTION: 'edit-payment-instruction',
  EDIT_DJC_PAYMENT: 'edit-djc-payment',
  DECLINE_PAYMENT_INSTRUCTION: 'decline-payment-instruction',
  DECLINE_DJC_PAYMENT_INSTRUCTION: 'decline-djc-payment-instruction',
  GLOBAL_COMPANIES_PAYMENTS_AT_A_GLANCE: 'global-companies-payments-at-a-glance',
  LW_REMINDER: 'lw_reminder',
  LW_NOT_REQUIRED: 'lw_not_required',
  VIEW_LIEN_WAIVER: 'view-lien-waiver',
  DOWNLOAD_LIEN_WAIVER: 'download-lien-waiver',
};
